.root {
  font-family: "Actay";
  background-color: var(--mantine-color-gray-5);
  border-radius: 8px;
  color: var(--mantine-color-white);
  font-size: 12px;
  padding: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-all;
}

.icon {
  vertical-align: middle;
  cursor: pointer;
}

.removeButton {
  display: flex;
  justify-content: flex-end;
  min-width: 24px;
}