.iconContainer {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  background-color: var(--mantine-color-violet-6);
}

.icon {
  color: white;
  height: 50%;
  width: 50%;
}