@import "/src/shared/static/styles/mixins.scss";

.actionsContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;

  justify-content: flex-end;
}

.noOptions {
  color: var(--mantine-color-gray-4);
  font-family: "Actay Wide";
  font-size: 12px;
}

.button {
  font-family: "Actay Wide";
  border: 1px solid var(--mantine-color-gray-3);
  height: 34px;
  border-radius: 6px;
  padding: 9px 13px;

  @include isMobile {
    font-size: 12px;
    padding: 9px 10px;
  }
}

.exchangeButton {
  @include isMobile {
    order: 2;
  }
  @include notMobile {
    width: 34px;
    padding: 0;
  }
}

.withdrawButton {
  @include isMobile {
    order: 1;
  }
}

.depositButton {
  @include isMobile {
    order: 3;
  }
}

.exchangeIcon {
  @include isMobile {
    display: none;
  }
  @media (max-width: 370px) {
    display: block;
  }
}

.exchangeText {
  display: none;
  @include isMobile {
    display: block;
  }
  @media (max-width: 370px) {
    display: none;
  }
}
