@import "/src/shared/static/styles/mixins.scss";

.levelDescriptionList {
  font-family: "Actay Wide";
  list-style: unset;

  li {
    margin-bottom: 14px;
  }
}

.contactButton {
  @include isMobile {
    width: 100%;
  }
}