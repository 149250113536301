@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  padding: 24px;
  height: 100%;
}

.mainContainer {
  display: grid;
  grid-template-columns: 5fr 340px;
  grid-template-rows: auto 1fr;
  flex-grow: 1;
  gap: 24px 32px;

  font-size: 14px;

  @include isMobile {
    display: flex;
    flex-direction: column;
  }
}

.copyIcon {
  color: var(--mantine-color-violet-6);
}

.iconContainer {
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--mantine-color-violet-6);
}

.coinIcon {
  color: white;
  width: 13px;
  height: 13px;
}

.depositCard {
  display: flex;
  padding: 0;
  flex-direction: column;
  gap: 24px;
  grid-row: 1/3;
  overflow: hidden;
  flex-shrink: 0;
}

.cardTitle {
  grid-column: 1/3;
}

.cardMainContent {
  flex-grow: 1;
  padding: 24px;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  @include isMobile {
    display: flex;
    flex-direction: column;
  }
}

.footer {
  display: flex;
  gap: 24px;
  align-items: center;
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-4);
  padding: 24px;
  background-color: var(--mantine-color-gray-0);
  border-top: 1px var(--mantine-color-gray-3) solid;

  @include isMobile {
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }

  & .submit {
    @include isMobile {
      align-self: stretch;
    }
  }
}

.address {
  grid-column: 1/3;
  font-size: 16px;
}

.label {
  font-family: "Actay Wide";
  font-size: 14px;
}

.combinedInputsContainer {
  grid-column: 1/3;
}

.upperInput {
  border-radius: 6px 6px 0 0;
  box-shadow: none;
  border-color: var(--mantine-color-gray-3);
  &::placeholder {
    color: var(--mantine-color-gray-9);
  }
}

.lowerInput:disabled {
  opacity: 1;
  border-radius: 0 0 6px 6px;
  color: var(--mantine-color-gray-4);
  border-top: 0;
  border-color: var(--mantine-color-gray-3);
}

.inputRightSectionLower {
  color: var(--mantine-color-gray-4);
  justify-content: right;
  margin-right: 12px;
}

.inputRightSectionUpper {
  justify-content: right;
  margin-right: 12px;
}

.ownAccountInfoIcon {
  color: var(--mantine-color-gray-4);
}

.isOwnAccountContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  grid-column: 1/3;
}

.checkboxLabel {
  color: var(--mantine-color-gray-7);
  font-family: "Actay Wide";
  font-size: 12px;
}

.checkbox {
  display: flex;
}

.pofWarning {
  grid-column: 1/3;
}