@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.mainContainer {
  display: grid;
  grid-template-columns: 5fr 340px;
  grid-template-rows: auto 1fr;
  flex-grow: 1;
  gap: 24px 32px;

  @include isMobile {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px 32px;
  padding: 24px;
}

.contactsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;

  font-size: 14px;

  @include isMobile {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.fiatCard {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: scroll;
  grid-row: 1/3;

  @include isMobile {
    overflow: initial;
  }
}

.contactsContent {
  font-family: "Actay Wide";
  font-size: 14px;
  line-height: 20px;
}

.contactsHeader {
  font-family: "Actay";
  font-size: 14px;
  line-height: 20px;
}

.contactsColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.form {
  background-color: var(--mantine-color-gray-1);
  font-size: 12px;
  font-family: "Actay Wide";

  border-radius: 6px;

  padding: 24px;
}

.getCodeButton {
  margin: 12px 12px 12px 0;
}

.getCodeButton.sent {
  background-color: var(--mantine-color-gray-3);
  color: black;
}

.maps {
  width: 100%;
  flex-grow: 1;
}

.segmentSwitchLabel {
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-4);
}

.segmentSwitchLabelActive {
  color: white;
}