.commissionIcon {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  cursor: pointer;
}

.popover {
  white-space: normal;
  color: black;
  font-size: 12px;
  font-family: "Actay Wide";
}