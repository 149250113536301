@import "/src/shared/static/styles/mixins.scss";

.modal {
  padding: 0;
}

.buttonsContainer {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  padding: 12px 24px;
  background-color: var(--mantine-color-gray-0);
  border-radius: 0 0 8px 8px;
  align-items: center;

  @include isMobile {
    flex-direction: column;
    align-items: stretch;
  }
}

.headerSection {
  display: flex;
  gap: 16px;
  font-size: 14px;

  @include isMobile {
    flex-direction: column;
  }
}

.header {
  align-items: flex-start;
  padding: 24px 24px 0 24px;
}

.headIconContainer {
  flex-shrink: 0;
  background-color: var(--mantine-color-purpleUI-0);
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.danger {
    background-color: var(--mantine-color-redUI-0);
  }
}

.headIcon {
  color: var(--mantine-color-purpleUI-5);
  width: 18px;
  height: 18px;
  &.danger {
    color: var(--mantine-color-redUI-5);
  }
}

.contentSection {
  width: 100%;
  padding: 0 24px 24px 24px;
}

.closeButton {
  &:focus {
    outline: none;
  }
  &.centered {
    position: absolute;
    right: 24px;
  }
}

.successTitle {
  margin-right: 0;
}

.successHeader {
  justify-content: center;
}

.successIcon {
  color: var(--mantine-color-green-5);
  width: 40px;
  height: 40px;
}