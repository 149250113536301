@import "../../shared/utils/styleHelpers.module.scss";
@import "../../shared/static/styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 24px;
  padding: 24px;
  grid-template-columns: 1fr;
  grid-auto-rows: auto 1fr;
  overflow-y: auto;
  overflow-x: hidden;

  @include notDesktop {
    row-gap: 16px;
  }
}

.exchangeAndHistoryContainer {
  flex-grow: 1;
  width: 100%;
  display: grid;
  column-gap: 24px;
  grid-template-columns: 2fr 1fr;

  @include notDesktop {
    row-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}

.exchangeCard {
  background-color: var(--mantine-color-white);
  border-radius: 12px;
  border: 0 transparent solid;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.exchangeCardContent {
  padding: 24px 80px;

  @include isMobile {
    padding: 16px 16px 16px 8px;
  }
}

.exchangeCardFooter {
  padding: 24px 130px;
  margin-top: auto;
  margin-bottom: 0;
  background: var(--mantine-color-gray-1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;

  @include isMobile {
    padding: 16px;
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }
}

.timeLineRoot {
  & div[class*="itemBullet"] {
    background: white;
    border-width: 0;
  }
}

.timeLineItem {
  @include isMobile {
    padding-left: 18px;
  }
}

.fees {
  display: inline-flex;
  flex-direction: column;

  margin: auto;
  font-size: 12px;
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-4);
}

.feeRow {
  display: flex;
  align-items: center;
  gap: 6px;
}

.feesIcon {
  width: 17px;
  height: 19px;
  color: var(--mantine-color-gray-6);
  margin: auto 8px;
  cursor: pointer;
}

.spacer {
  @include isMobile {
    display: none;
  }
}

.submit {
  @include isMobile {
    align-self: stretch;
  }
}

.stepTitle {
  font-size: 18px;
  @include isMobile {
    font-size: 14px;
  }
}
