@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.rootDesktop {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @include isMobile {
    display: none;
  }
}

.rootMobile {
  display: none;
  @include isMobile {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.table {
  font-family: "Actay Wide";
  margin-top: 12px;

  width: 100%;
  border-collapse: collapse;

  tbody > tr > td {
  }
}

.walletRow {
  display: flex;
  justify-content: space-between;
}

.controls {
  display: flex;
}

.tableBody > *:last-child {
  border-bottom: 1px solid var(--mantine-color-gray-2);
}

.tableRow {
  border-top: 1px solid var(--mantine-color-gray-2);
  font-size: 12px;
  line-height: 16px;

  width: 100%;
}

.tableHeadRow {
  @extend .tableRow;
}

.tableRow > .tableHeadCell {
  padding: 8px 0;
  color: var(--mantine-color-gray-4);
  text-transform: uppercase;
}

.tableRow .cell {
  padding: 16px 0;
}

.tableRow .cell {
  padding: 16px 0;
}

.cell {

  &:nth-child(1) {
    min-width: 100px;
    max-width: 190px;
    text-align: left;

    padding-left: 0;
  }

  &:nth-child(2) {
    width: auto;
    text-align: right;
  }

  &:nth-child(3) {
    min-width: 300px;
    width: 40%;
    max-width: 300px;
    text-align: right;

    padding-right: 0;
  }
}

.tableRow > *:not(:first-child) {
  text-align: right;
}

.tableRow > *:not(:last-child) {
  max-width: 70px;
}

.icon {
  min-width: 32px;
  width: 32px;
  height: 32px;
}

.currencyInfoContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.currencyDescriptionContainer {
  overflow: hidden;
}

.currencyTicker {
  font-size: 14px;
  line-height: 16px;
}

.currencyName {
  font-size: 12px;
  color: var(--mantine-color-gray-4);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobileRowsContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-family: "Actay Wide";

  @include isMobile {
    margin-bottom: 8px;
  }
}

.balanceCell {
  display: grid;
  gap: 4px;
  font-size: 14px;

  > * {
    line-height: 18px;

    &:nth-child(2) {
      color: var(--mantine-color-gray-4);
    }
  }

  @include isMobile {
    text-align: right;
  }
}

.currencyFirstBlock {
  display: flex;
  justify-content: space-between;
}

.currencyContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}