@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  font-family: "Actay Wide";
  font-size: 12px;
  line-height: 16px;

  overflow-y: auto;
  overflow-x: hidden;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 24px 48px 24px 24px;
  @include isMobile {
    padding: 24px;
    overflow: initial;
    display: none;
  }
}