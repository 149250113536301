@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins";

.mainContainer {
  display: grid;
  grid-auto-rows: auto 1fr;
  grid-template-columns: 2fr 1fr;
  flex-grow: 1;
  gap: 24px;

  font-family: "Actay Wide";
  font-size: 12px;
  line-height: 16px;

  &.hasBlocked {
    grid-auto-rows: auto auto 1fr;
  }

  @include isMobile {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1224px) {
    grid-template-columns: 1fr;
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  padding: 24px;

  overflow: auto;
}

.balanceContainer {
  grid-column: 1/3;

  @include full {
    height: 72px;
  }
}

.verifyContainer {
  @extend .balanceContainer;

  font-family: "Actay Wide";
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  @include isMobile {
    flex-direction: column;
    align-items: stretch;
  }
}

.verifyContainerButtons {
  display: flex;
  gap: 12px;

  @include isMobile {
    flex-direction: column;
  }
}

.verifyContainerText {
  font-family: "Actay Wide";
  line-height: 16px;
  color: var(--mantine-color-gray-9);
}

.balanceHeader {
  font-size: 16px;
  line-height: 24px;
}

.balance {
  font-family: "Actay Wide";
  @include isMobile {
    font-size: 30px;
  }
}

.balanceChange {
  color: var(--mantine-color-green-5);
}

.balanceFractional {
  font-size: 24px;
  color: var(--mantine-color-gray-6);
}

.card.warn {
  background-color: var(--mantine-color-orange-0);
}

.button {
  @include isMobile {
    width: 100%;
  }
}

.currencyIcon {
  height: 28px;
}