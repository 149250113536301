@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px 32px;
  padding: 24px;
}

.mainContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  flex-grow: 1;
  gap: 24px 32px;

  @include isMobile {
    display: flex;
    flex-direction: column;
  }
}

.fiatCard {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: scroll;

  @include isMobile {
    overflow: initial;
  }
}

.segmentSwitchLabel {
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-4);
}

.segmentSwitchLabelActive {
  color: white;
}