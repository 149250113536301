@import "/src/shared/utils/styleHelpers.module.scss";

.root {
  min-height: 190px;

  font-family: "Actay Wide";
  font-size: 12px;
  line-height: 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bottomText {
  color: var(--mantine-color-gray-4);
}

.title {
  font-family: "Actay Wide";
  font-size: 18px;
}

.primary {
  color: var(--mantine-color-purpleUI-6);
}

.warn {
  color: var(--mantine-color-orange-6);
}

.danger {
  color: var(--mantine-color-red-6);
}

.limitAmounts {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
}

.currencyEstimated {
  font-size: 12px;
  color: var(--mantine-color-gray-4);
}

.maxAmount {
  color: var(--mantine-color-gray-4);
}

.bottomLabelsContainer {
  display: flex;
  justify-content: space-between;
  color: var(--mantine-color-gray-4);
}

.limitBarContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.amountContainer {
  display: flex;
  flex-direction: column;
  &.reverseAlign {
    align-items: flex-end;
  }
}