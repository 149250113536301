.root {
  position: relative;
}

.modalHeader {
  font-family: "Actay Wide";
  font-size: 24px;
  line-height: 28px;
}

.modal {
  width: 424px;
}

.button {
  font-family: "Actay Wide";
  font-size: 12px;
}