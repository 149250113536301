.root {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.questionNavItem {
  height: 4px;
  flex-grow: 1;
  background-color: var(--mantine-color-gray-3);
  border-radius: 10px;
}

.questionNavItem.complete {
  background-color: var(--mantine-color-violet-3);
}

.questionNavItem.active {
  background-color: var(--mantine-color-violet-6);
  height: 8px;
}
