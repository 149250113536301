@import "/src/shared/static/styles/mixins.scss";

.modal {
  width: 524px;
  border-radius: 8px;

  @include isMobile {
    width: 344px;
  }
}

.button {
  @include isMobile {
    width: 100%;
  }
}

.laterButton {
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-6);
  font-size: 14px;
  cursor: pointer;
  justify-self: flex-start;
  @include isMobile {
    text-align: center;
  }
}

.modalFooter {
  justify-content: space-between;
  @include isMobile {
    justify-content: flex-start;
  }
}

.subHeader {
  padding-top: 8px;
}

.anchor {
  font-family: "Actay Wide";
  color: var(--mantine-color-purpleUI-6);
}