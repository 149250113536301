@import "/src/shared/static/styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  &.fullWidth {
    align-items: stretch;
  }
}

.levelDescriptionContainer {
  width: 440px;
  padding: 24px;
  &.fullWidth {
    width: initial;
  }

  @include isMobile {
    padding: 0;
    width: initial;
  }
}
