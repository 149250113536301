.root {
  flex-grow: 1;
}

.accountFieldTitle {
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-4);
}

.accountFieldInfo {
  color: var(--mantine-color-gray-9);
}
