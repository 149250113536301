@import "/src/shared/static/styles/mixins.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  position: sticky;
  z-index: 10; // need this to display dropdown menu properly
  top: 0;
}

.desktopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  height: 64px;
  border-bottom: 1px solid rgba(229, 231, 235, 1);
  background-color: var(--mantine-color-white);
}

.pageTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  cursor: default;

  @include isMobile {
    font-size: 20px;
  }
}

.userImageContainer {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
}

.userImageBadge {
  position: absolute;
  z-index: 1;
  left: -5.6px;
  bottom: 0;
}

.avatarUnverified {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
}

.avatarVerified {
  display: grid;
  place-items: center;
  height: 40px;
  width: 40px;
  background: var(--mantine-color-purpleUI-5);
  border-radius: 50%;
  > span {
    margin-top: 2px;
    font-family: "Actay" sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: white;
  }
}

.menuContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 100%;

  user-select: none;
}

.userInfoContainer {
  display: flex;
  align-items: center;
  text-align: right;
  gap: 12px;
  cursor: pointer;
  height: 100%;
  transition: background-color 0.15s ease;
  padding: 12px 16px;

  &:hover {
    background-color: var(--mantine-color-gray-1);
  }
}

.userInfoLeftColumn {
  display: flex;
  flex-direction: column;

  @include isMobile {
    display: none;
  }
}

.userInfoName {
  font-size: 16px;
  line-height: 24px;
  min-height: 24px;
  color: var(--mantine-color-gray-9);
}

.verified {
  font-size: 12px;
  line-height: 16px;
  color: var(--mantine-color-gray-4);
}

.notVerified {
  font-size: 12px;
  line-height: 16px;
  color: var(--mantine-color-red-6); // todo change to redUI
}

.userName {
  font-size: 16px;
  line-height: 24px;
}

.dropdownArrow {
  color: var(--mantine-color-gray-6);
  transition: transform 0.15s ease;
}

.dropdownArrowOpen {
  transform: rotate(180deg);
}

.divider {
  width: 1px;
  height: 100%;
  background-color: var(--mantine-color-gray-3);
}

.backLink {
  display: flex;
  gap: 24px;
  align-items: center;
  &,
  > * {
    cursor: pointer;
  }
}

.dropdown {
  min-width: 240px;
  top: 68px;
  padding: 4px 0;

  border-radius: 6px;
  border: none;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.dropdownHorizontalDivider {
  height: 1px;
  width: calc(100% + 8px);
  margin: 4px -4px;
  background-color: var(--mantine-color-gray-2);
}

.mobileHeader {
  display: none;
  background-color: var(--mantine-color-gray-9);
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  @include isMobile() {
    display: flex;
  }
}

.logo {
  height: 100%;
}

.burger {
  position: absolute;
  right: 16px;
  z-index: 12;
}

.navbar {
  position: absolute;
  z-index: 11;
}