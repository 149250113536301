@import "/src/shared/static/styles/mixins.scss";

.modal {
  width: 520px;
  @include isMobile {
    width: initial;
  }
}

.success {
  color: var(--mantine-color-green-4);
  width: 40px;
  height: 40px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  text-align: center;
}

.title {
  font-size: 18px;
}

.text {
  font-size: 16px;
  line-height: 24px;
}

.warn {
  font-family: "Actay Wide";
  font-size: 12px;
  padding: 8px;
  background-color: var(--mantine-color-red-1);
}

.finishScreenButton {
  @include isMobile {
    width: 100%;
  }
}
