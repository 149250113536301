@import "/src/shared/static/styles/mixins.scss";

.blockedText {
  display: block;
  @include isMobile {
    display: none;
  }
}

.blockedTextMobile {
  display: none;
  @include isMobile {
    display: block;
  }
}

.modalButton {
  @include isMobile {
    width: 100%;
  }
}