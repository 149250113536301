@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  display: grid;
  grid-template-columns: 2fr 1fr;
  flex-grow: 1;
  gap: 24px 32px;

  padding: 24px;
  overflow: scroll;

  font-size: 14px;

  @include isMobile {
    overflow: initial;
  }
}

.withdrawCard {
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;

  flex-shrink: 0;
}

.mainContent {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include isMobile {
    padding: 16px;
  }
}

.footer {
  display: flex;
  gap: 24px;
  align-items: center;
  padding: 24px;
  background-color: var(--mantine-color-gray-0);
  border-top: 1px var(--mantine-color-gray-3) solid;

  @include isMobile {
    flex-direction: column;
    padding: 16px;
  }
}

.submitButton {
  align-self: stretch;
}

.paste {
  color: var(--mantine-color-violet-6);
}

.iconContainer {
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--mantine-color-violet-6);
}

.coinIcon {
  color: white;
  width: 13px;
  height: 13px;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;

  @include isMobile {
    display: flex;
    flex-direction: column;
  }
}

.depositCard {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
}

.label {
  font-family: "Actay Wide";
  font-size: 14px;
}

.address {
  grid-column: 1/3;
}

.checkboxLabel {
  color: var(--mantine-color-gray-7);
  font-family: "Actay Wide";
  font-size: 12px;
}

.checkbox {
  grid-column: 1/3;
  display: flex;
}

.addressInput {
  padding-right: 90px;
}

.addressRightSection {
  color: var(--mantine-color-violet-6);
  font-family: "Actay Wide";
  font-size: 14px;
  justify-content: right;
  margin-right: 12px;
  cursor: pointer;
}

.cardTitle {
  @include isMobile {
    font-size: 18px;
  }
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: "Actay Wide";

  @include isMobile {
    flex-direction: column-reverse;
  }
}

.actions {
  margin-left: 16px;
  color: var(--mantine-color-violet-6);
  display: inline-flex;
  gap: 12px;
}

.action {
  cursor: pointer;
}

.exchangeBlock {
  grid-column: 1/3;
}

.combinedInputs {
  grid-column: 1/3;
}

.inputRightSectionLower {
  color: var(--mantine-color-gray-5);
  justify-content: right;
  margin-right: 12px;
}

.inputRightSectionUpper {
  justify-content: right;
  margin-right: 12px;
}

.upperInput {
  border-radius: 6px 6px 0 0;
  box-shadow: none;
}

.lowerInput:disabled {
  border-top: 0;
  color: var(--mantine-color-gray-5);
  border-radius: 0 0 6px 6px;
}

.commission {
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-4);
}

.commissionIcon {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  cursor: pointer;
}

.popover {
  color: black;
  font-size: 12px;
}

.notification {
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: min(400px, 90%);
  z-index: 100;
}
