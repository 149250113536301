@import "/src/shared/static/styles/mixins.scss";

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dropdownArrow {
  color: var(--mantine-color-gray-6);
  transition: transform 0.15s ease;
  &.open {
    transform: rotate(180deg);
  }
}

.data {
  display: grid;
  grid-template-columns: minmax(auto, 100px) 1fr;
  gap: 12px;
  margin-top: 12px;

  @include isMobile {
    display: block;
  }
}

.fieldValue {
  font-size: 12px;
  font-family: "Actay";
}

.fieldName {
  font-size: 12px;
  color: var(--mantine-color-gray-4);
}