@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  font-family: "Actay Wide";
  font-size: 12px;
  line-height: 16px;

  display: flex;
  flex-direction: column;
}

.historyItem {
  display: flex;
  justify-content: space-between;

  border-bottom: 1px var(--mantine-color-gray-3) solid;
  padding: 12px 0;
}

.historyDateItem {
  &:not(:first-of-type) {
    margin-top: 30px;
  }
}
.historyList {
  display: flex;
  flex-direction: column;
}

.greyedText {
  color: var(--mantine-color-gray-5);
}

.amountDeposit {
  color: var(--mantine-color-green-5);
}

.amountExchange {
  color: var(--mantine-color-green-5);
}

.amountError {
  color: var(--mantine-color-red-4);
}

.title {
  font-family: "Actay Wide";
  font-size: 18px;
}

.noDataIcon {
  color: var(--mantine-color-gray-5);
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  gap: 12px;
  align-items: center;

  min-height: 168px;
}

.popover {
  color: black;
}

.hintIcon {
  vertical-align: bottom;
}

.approveButton {
  font-size: var(--mantine-font-size-xs);
}