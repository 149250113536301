@import "/src/shared/static/styles/mixins.scss";

.root {
  width: 100%;
  display: flex;
  min-height: 100vh;
}

.content {
  background-color: var(--mantine-color-gray-1);
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.navbar {
  @include isMobile {
    display: none;
  }
}
