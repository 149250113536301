.root {
  height: 350px;
  display: flex;
  flex-direction: column;
  padding: 24px 28px;
  border-radius: var(--mantine-radius-md);
}

.cardHeader {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  &,
  > * {
    font-size: 18px;
    line-height: 28px;
  }
}

.cardContent {
  flex-grow: 1;
}