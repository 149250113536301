@import "/src/shared/static/styles/mixins.scss";

.root {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Actay Wide";
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.questionOf {
  color: var(--mantine-color-violet-6);
}

.questionNavContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.questionNavItem {
  height: 4px;
  flex-grow: 1;
  background-color: var(--mantine-color-gray-3);
  border-radius: 10px;
}

.questionNavItem.active {
  height: 8px;
}

.questionNavItem.incorrect {
  background-color: var(--mantine-color-red-3);
}

.questionNavItem.correct {
  background-color: var(--mantine-color-green-3);
}

.questionNavItem.answered {
  background-color: var(--mantine-color-violet-3);
}

.questionNavItem.answered.active {
  background-color: var(--mantine-color-violet-6);
}

.questionNavItem.correct.active {
  background-color: var(--mantine-color-green-5);
}

.questionNavItem.incorrect.active {
  background-color: var(--mantine-color-red-6);
}

.radio {
  display: block;
  font-family: "Actay";
}

.arrowsContainer {
  display: flex;
  justify-content: space-between;
  justify-self: flex-end;
  margin-top: 24px;
}

.title {
  font-size: 18px;
  line-height: 24px;
  font-family: "Actay Wide";
}

.button {
  font-family: "Actay Wide";
  @include isMobile {
    width: 100%;
  }
}

.success {
  color: var(--mantine-color-green-5);
}

.fail {
  color: var(--mantine-color-red-6);
}

.finishScreenTitle {
  @extend .title;

  margin-top: 16px;
}
.finishScreenText {
  font-family: "Actay Wide";
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: var(--mantine-color-gray-4);
}

.finishScreenRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
  margin-top: 28px;
}

.finishScreenButton {
  margin-top: 40px;
  width: 100%;
  font-size: 16px;
  height: 42px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.laterButton {
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-6);
  font-size: 14px;
  cursor: pointer;
  justify-self: flex-start;
  @include isMobile {
    text-align: center;
  }
}

.buttonsContainer {
  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  align-items: stretch;
}
