@import "/src/shared/static/styles/mixins";

.infoBlock {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-family: "Actay Wide";

  @include isMobile {
    flex-direction: column-reverse;
    font-size: 14px;
  }
}

.action {
  cursor: pointer;
  margin-left: 16px;
  color: var(--mantine-color-violet-6);
}

.allowedBalance {
  font-size: 14px;
}

.estimateContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.swap {
  position: absolute;
  right: 0;
  top: calc(100% + 16px);
  color: var(--mantine-color-purpleUI-6);
  width: 40px;
  height: 40px;
  padding: 0px;
  z-index: 1;
}