@import "/src/shared/static/styles/mixins.scss";

.root {
  background-color: var(--mantine-color-white);
  border-radius: 12px;
  border: 0px transparent solid;
  padding: 24px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

  @include isMobile {
    padding: 16px;
  }
  &.info {
    padding: 16px;
    background-color: var(--mantine-color-gray-1);
  }
  &.warning {
    font-size: 12px;
    padding: 16px;
    font-family: "Actay Wide";
    background-color: var(--mantine-color-orange-0);
  }
  &.danger {
    font-size: 12px;
    padding: 16px;
    font-family: "Actay Wide";
    background-color: var(--mantine-color-red-1);
  }
}
