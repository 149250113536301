.value {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 12px;
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-4);
}

.qrContainer {
  display: flex;
  justify-content: center;
}

.qr {
  width: 220px;
  height: 220px;
  padding: var(--mantine-spacing-md);
}

.title {
  font-family: Actay Wide;
  font-size: 18px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--mantine-spacing-xl);
}