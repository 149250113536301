@import "/src/shared/static/styles/mixins.scss";

.root {
  display: flex;
  justify-content: space-between;
  font-family: "Actay Wide";
  font-size: var(--mantine-font-size-sm);
  grid-column: 1/-1;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--mantine-spacing-xs);

  @include isMobile {
    flex-direction: column;
    align-items: stretch;
  }
}