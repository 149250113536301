@import "/src/shared/static/styles/mixins.scss";

.root {
  width: 100%;
}

.container {
  max-width: 540px;
  height: 400px;
  margin: 30px auto;

  @include isMobile {
    max-width: initial;
    height: initial;
    margin: 8px 0;
  }
}

.chainContainer {
  display: flex;
  gap: 26px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  @include isMobile {
    gap: 12px;
  }
}

.currencyIcon {
  width: 72px;
  height: 72px;
  display: flex;
  border-radius: 36px;
  svg {
    height: 36px;
    margin: auto;
  }

  @include isMobile {
    width: 48px;
    height: 48px;
    svg {
      width: 25px;
      height: 25px;
      margin: auto;
    }
  }
}

.statusIcon {
  width: 48px;
  height: 48px;
  margin: auto;
  display: flex;
  svg {
    height: 48px;
    margin: auto;
  }
  &.info {
    color: var(--mantine-color-gray-4);
  }

  @include isMobile {
    width: 36px;
    height: 36px;
    svg {
      width: 25px;
      height: 25px;
      margin: auto;
    }
  }
}

.title {
  @include isMobile {
    font-size: 18px;
  }
}

.textContent {
  font-family: "Actay Wide";
  text-align: center;
  color: var(--mantine-color-gray-4);
  @include isMobile {
    font-size: 12px;
  }
}

.amount {
  text-align: center;
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-9);
  font-size: 18px;
}

.statusIconError {
  svg {
    color: var(--mantine-color-red-6);
  }
}

.statusIconSuccess {
  svg {
    color: var(--mantine-color-green-5);
  }
}

.currencyIconDefault {
  background-color: var(--mantine-color-violet-5);
  svg {
    color: white;
  }
}

.currencyIconError {
  background-color: var(--mantine-color-gray-2);
  svg {
    color: var(--mantine-color-gray-4);
  }
}

.dividerIcon {
  margin: 6px auto 0;
  svg {
    height: 24px;
    color: var(--mantine-color-red-5);
    margin: auto;
  }
}

.actionButton {
  width: 300px;
  font-size: 16px;
}

.buttonIcon {
  height: 20px;
  color: var(--mantine-color-gray-7);
}

.textError {
  color: var(--mantine-color-redUI-5);
  font-family: "Actay Wide";
}

.buttonIcon.dark {
  color: white;
}

.chainDivider {
  width: 103px;

  @include isMobile {
    width: 48px;
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
