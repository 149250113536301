@import "/src/shared/static/styles/mixins.scss";

.titleContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  min-height: 35px;
}

.withRemove {
  justify-content: flex-start;
}

.swapButton {
  width: 40px;
  height: 40px;
  padding: 0px;
  border: 1px solid var(--mantine-color-gray-3);
  position: absolute;
  right: 0;
  bottom: 6px;
}

.swapIcon {
  width: 18px;
  height: 16px;
  color: var(--mantine-color-purpleUI-6);
}

.stepTitle {
  font-size: 18px;
  @include isMobile {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}

.estimateContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
