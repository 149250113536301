@import "/src/shared/static/styles/mixins.scss";

.modal {
  width: max-content;
  min-width: 450px;
  @include isMobile {
    min-width: initial;
  }
}

.button {
  font-family: "Actay Wide";
}

.infoContainer {
  background-color: var(--mantine-color-gray-1);
  padding: 12px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.infoHeader {
  color: var(--mantine-color-gray-6);
  font-family: "Actay Wide";
}

.infoValue {
  font-size: 16px;
  color: black;
}

.comment {
  font-size: 12px;
  color: var(--mantine-color-gray-7);
}

.commentEmail {
  color: black;
}

.inputContainer {
  margin-top: 16px;
}

.label {
  font-family: "Actay Wide";
  font-size: 14px;
}