@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  display: grid;
  grid-template-columns: 1fr 2fr;
  flex-grow: 1;
  gap: 24px 32px;
  padding: 24px;

  @include isMobile {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.contentCard {
  @include isMobile {
    padding: 24px;
  }
}

.title {
  font-size: 18px;
}

.sumSubContainer {
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
