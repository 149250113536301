@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;

  overflow-x: hidden;
  overflow-y: auto;
}

.CheckBoxLabel {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & label {
    font-weight: 800;
    line-height: 22px;
  }
}

.itemContainer {
  padding: 8px 12px;
  font-family: Actay;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  color: #000;
  border-radius: 4px;

  display: flex;
  gap: 8px;
  align-items: center;
}

.itemContainer:hover {
  background-color: var(--mantine-color-gray-1);
}

.modalHeader {
  font-family: "Actay Wide";
  font-size: 24px;
  line-height: 28px;
  padding-left: 56px;
  svg {
    position: absolute;
    height: 40px;
    left: 20px;
    color: var(--mantine-color-red-5);
  }
}

.modalContent {
  padding: 0 16px 0 56px;
}

.modal {
  width: 524px;
  padding-top: 20px;
}

.changePasswordModal {
  @extend .modal;
}

.changePasswordModalHeaderContainer {
  display: flex;
  align-items: center;
}

.changePasswordModalBodyContainer {
  display: flex;
  flex-direction: column;

  padding-top: 6px;

  gap: 12px;

  input {
    height: 42px;
  }

  > button {
    margin-top: 12px;
  }
}

.button {
  @include isMobile {
    width: 100%;
  }
}