@import "/src/shared//utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.mainContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  flex-grow: 1;
  gap: 24px 32px;

  font-size: 14px;

  @include isMobile {
    display: flex;
    flex-direction: column;
  }
}


.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  flex-grow: 1;
}
