@import "/src/shared//utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  padding: 24px;
  background-color: var(--mantine-color-gray-1);
  border-radius: 12px;
  &.noContainer {
    padding: initial;
    background-color: initial;
  }
}

.selectTicker {
  font-family: "Actay Wide";
  text-transform: uppercase;
  padding-left: 8px;
  position: relative;
  top: 0.5px;
}

.selectIcon {
  width: 70px;
  left: 11px;
  font-size: 14px;
  color: var(--mantine-color-black);
  @include isMobile {
    font-size: 12px;
  }
}

.combinedInputs {
  grid-column: 1/3;
  margin-bottom: 12px;
}

.upperInput {
  box-shadow: none;
  padding-left: 81px;
  font-size: 14px;
  border-bottom: 0px;
  border-radius: var(--mantine-radius-sm) var(--mantine-radius-sm) 0 0;
  border-color: var(--mantine-color-gray-3);
  @include isMobile {
    font-size: 12px;
  }
}

.lowerInput {
  border-radius: 0 0 var(--mantine-radius-sm) var(--mantine-radius-sm);
  color: var(--mantine-color-gray-5);
  border-color: var(--mantine-color-gray-3);
  &::placeholder {
    color: var(--mantine-color-gray-9);
  }
}

.inputRightSectionLower {
  color: var(--mantine-color-gray-5);
  justify-content: right;
  margin-right: 12px;
}

.inputRightSectionUpper {
  justify-content: right;
  margin-right: 12px;
}

.footer {
  font-family: "Actay Wide";
  font-size: 12px;
  color: var(--mantine-color-gray-4);
  display: flex;
  align-items: str;
  gap: 24px;
  align-items: center;
  @include isMobile {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }
}

.submitButton {
  @include isMobile {
    align-self: stretch;
  }
}

.warning {
  padding: 16px;
  font-family: "Actay Wide";
  margin-bottom: 24px;
}

.checkboxLabel {
  color: var(--mantine-color-gray-7);
  font-family: "Actay Wide";
  font-size: 12px;
}

.checkbox {
  display: flex;
}

.ownAccountInfoIcon {
  color: var(--mantine-color-gray-4);
}

.invalid {
  color: var(--mantine-color-black);
  border-color: var(--mantine-color-redUI-4);
  &:focus {
    border-color: var(--mantine-color-redUI-4);
  }
}

.isOwnAccountContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
