@import "/src/shared/static/styles/mixins.scss";

.card {
  background-color: var(--mantine-color-white);
  border-radius: 12px;
  border: 0px transparent solid;
  padding: 24px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

  @include isMobile {
    padding: 16px;
  }
}

.noData {
  display: flex;
  flex-direction: column;
}

.noDataIcon {
  color: var(--mantine-color-gray-5);
}

.noDataLabel {
  margin-top: 12px;
  font-family: "Actay Wide";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--mantine-color-gray-6);
}
