@import "/src/shared/static/styles/mixins.scss";

.modalRoot {
  width: 519px;

  @include isMobile {
    width: initial;
  }
}

.infoContainer {
  background-color: var(--mantine-color-gray-1);
  padding: 12px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.infoHeader {
  color: var(--mantine-color-gray-6);
  font-family: "Actay Wide";
}

.infoValue {
  font-size: 16px;
  color: black;
}

.comment {
  font-size: 12px;
  color: var(--mantine-color-gray-7);
}

.commentEmail {
  color: black;
}

.inputContainer {
  margin-top: 16px;
}

.label {
  font-family: "Actay Wide";
  font-size: 14px;
}

.rightSection {
  width: max-content;
  padding-right: 12px;
  font-size: 14px;
  font-family: "Actay Wide";
  color: var(--mantine-color-purpleUI-6);

  @include isMobile {
    font-size: 12px;
  }
}

.rightSection .noColor {
  color: var(--mantine-color-gray-4);
}

.rightSection .active {
  cursor: pointer;
}