@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  font-size: 12px;
  font-family: "Actay Wide";

  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.getCodeButton {
  margin: 12px 12px 12px 0;
}

.getCodeButton.sent {
  background-color: var(--mantine-color-gray-3);
  color: black;
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: "Actay Wide";

  @include isMobile {
    flex-direction: column-reverse;
    gap: 4px;
  }
}

.actions {
  margin-left: 16px;
  color: var(--mantine-color-violet-6);
  display: inline-flex;
  gap: 12px;
}

.action {
  cursor: pointer;
}

.inputRightSectionUpper {
  justify-content: right;
  margin-right: 12px;
}

.submitBlock {
  display: flex;
  gap: 24px;

  @include isMobile {
    flex-direction: column;
    align-items: center;
  }
}

.submitButton {
  align-self: stretch;
}

.commissionContainer {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--mantine-color-gray-4);
}

.feesIcon {
  width: 17px;
  height: 19px;
  cursor: pointer;
}

.inputLabel {
  font-family: "Actay Wide";
}

.commissionIcon {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  cursor: pointer;
  color: var(--mantine-color-gray-4);
}

.isOwnAccountContainer {
  display: flex;
  align-items: center;
}

.checkboxLabel {
  font-family: "Actay Wide";
  font-size: 12px;
}

.checkbox {
  display: flex;
}

.isOwnAccountInfo {
  color: var(--mantine-color-gray-4);
}

.additionalInfoData {
  display: grid;
  grid-template-columns: minmax(auto, 100px) 1fr;
  gap: 12px;
  margin-top: 12px;

  @include isMobile {
    display: block;
  }
}

.additionalFieldValue {
  font-size: 12px;
  font-family: "Actay";
}

.additionalFieldName {
  font-size: 12px;
  color: var(--mantine-color-gray-4);
}