@import "/src/shared/static/styles/mixins.scss";

.details {
  background-color: var(--mantine-color-gray-1);
  font-size: 12px;
}

.fieldName {
  font-family: "Actay Wide";
  color: var(--mantine-color-gray-4);
  width: 120px;
}

.modal {
  width: 519px;
  @include isMobile {
    width: initial;
  }
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.footer {
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  @include isMobile {
    flex-direction: column;
  }
}

.closeButton {
  border: 1px solid var(--mantine-color-gray-3),
}

.warning {
  font-size: 12px;
}