@import "/src/shared/static/styles/mixins.scss";

.iconContainerValue {
  margin-left: 12px;
  font-family: 'Actay Wide', serif;
  line-height: 34px;
  height: 31px;
  color: black;
  span {
    margin: auto;
  }
}

.currencySelectInput {
  font-weight: normal;
}

.amountInput {
  margin-bottom: 0;
  & .amountInputField {
    background: var(--mantine-color-gray-0);
    border: 0;
    &::placeholder {
      color: var(--mantine-color-gray-9);
    }
    &:disabled {
      color: var(--mantine-color-gray-9);
      opacity: 1;
    }
  }
  & .amountInputRight {
    width: 180px;
    margin-right: 16px;
    margin-left: auto;
    text-align: right;
    justify-content: end;
    color: var(--mantine-color-gray-4);
    @include isMobile {
      width: 100px;
    }
  }
}

.inputWrapper {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid var(--mantine-color-gray-3);
  &.hasError {
    border-color: var(--mantine-color-redUI-5);
    margin-bottom: 10px;
  }
}

.inputWrapperAfter {
  border-bottom: 1px solid var(--mantine-color-gray-3);
  &.hasError {
    border-color: var(--mantine-color-redUI-5),
  }
}

.select {
  width: 100%;
  border: 0;
  & .selectInput {
    padding-top: 3px;
    border: 0;
    background: transparent;
    padding-left: 0;
  }
  & .selectWrapper {
    display: flex;
    padding-left: 12px;
  }
  & .selectIcon {
    width: auto;
    position: relative;
    box-sizing: border-box;
    padding: 0 12px 0 0;
  }
}
