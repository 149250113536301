@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.companyTypeContainer {
  display: flex;
  gap: 16px;
  align-items: center;
  background-color: var(--mantine-color-gray-1);
  padding: 12px 24px;
  cursor: pointer;
  @include isMobile {
    align-items: flex-start;
    padding: 16px 16px;
  }
}

.companyTypeTextContainer {
  flex-grow: 1;
  color: var(--mantine-color-gray-5);
}

.companyTypeTitle {
  color: var(--mantine-color-black);
}

.typeSelectLinkButton {
  color: var(--mantine-color-purpleUI-6);
  font-family: "Actay Wide";
  &:hover {
    color: var(--mantine-color-purpleUI-5);
  }
}

.verificationGuideLink {
  font-family: "Actay Wide";
  color: var(--mantine-color-purpleUI-6);
  &:hover {
    color: var(--mantine-color-purpleUI-5);
  }
}

.kybGuideList {
  list-style: auto;
  padding-left: 24px;
}

.typeCardRight {
  display: flex;
  align-items: center;

  flex-grow: 1;
  @include isMobile {
    align-items: flex-start;
    &.active {
      flex-direction: column;
    }
  }
}

.userTypeArrow {
  width: 8px;
  height: 14px;
  margin: 6px;
  flex-shrink: 0;
}

.userTypeIcon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  @include isMobile {
    width: 27px;
    height: 27px;
  }
}
