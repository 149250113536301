@import "/src/shared//utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include isMobile {
    padding-bottom: 24px;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px;

  text-align: center;
  font-family: "Actay Wide";
  @include isMobile {
    padding: 0;
  }
}

.iconContainer {
  width: 72px;
  height: 72px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--mantine-color-gray-2);
  margin-top: 32px;
  margin-bottom: 72px;
}

.coinIcon {
  color: var(--mantine-color-gray-4);
  width: 35px;
  height: 35px;
}

.text {
  color: var(--mantine-color-gray-4);
}

.textError {
  color: var(--mantine-color-redUI-5);
  font-family: "Actay Wide";
}

.statusIcon {
  color: var(--mantine-color-redUI-5);
  width: 40px;
  height: 40px;
}

.errorBlock {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 16px;
}

.footer {
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: center;
}

.buttonHistory {
  margin: 0;
  border: 1px solid var(--mantine-color-gray-4);
  color: black;
  @include isMobile {
    width: 100%;
    margin-top: 32px;
  }
}

.historyIcon {
  color: var(--mantine-color-gray-5);
  width: 16px;
  margin-right: 13px;
}