@import "/src/shared/utils/styleHelpers.module.scss";
@import "/src/shared/static/styles/mixins.scss";

.root {
  display: flex;
  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;
}

.historyContainer {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 18px 24px;
  display: flex;
  flex-direction: column;

  @include isMobile {
    padding: 12px 16px;
  }
}

.historyContainerDisplayNone {
  display: none;
}

.historyHeaderContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;

  @include isMobile {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 12px;
  }
}

.historyHeaderFiltersContainer {
  display: flex;
  gap: 12px;
  align-items: center;

  > *:nth-child(1) {
    width: 148px;
  }
  > *:nth-child(2) {
    width: 148px;
  }
}

.historyContainerNoData {
  @extend .historyContainer;

  display: grid;
  place-items: center;
}

.historyContainerNoDataInner {
  display: grid;
  place-items: center;
  gap: 12px;

  font-family: "Actay wide";
  color: var(--mantine-color-gray-9);
}

.noDataIcon {
  color: var(--mantine-color-gray-4);
}

.historyGroupContainer {
}

.historyItem {
  font-family: "Actay wide";
  background-color: var(--mantine-color-white);
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  &:last-child {
    border-bottom-color: transparent;
  }
}

.historyItemWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  cursor: pointer;
  overflow: auto;

  @include isMobile {
    padding: 8px 12px;
  }
}

.historyItemTitle {
  width: 100%;
  display: flex;
  span {
    color: black;
    font-weight: bold;
  }

  @include isMobile {
    font-size: 12px;
  }
}

.listsContainer {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dayListContainer {
  border-radius: 8px;
  border: 0 transparent solid;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.exchangePairs {
  color: gray;
  font-family: "Actay Wide";
  font-size: 14px;
  line-height: 14px;
  margin-left: 10px;
  color: #888 !important;
  svg {
    margin: auto 5px auto 5px;
    height: 12px;
  }

  @include isMobile {
    font-size: 12px;
  }
}

.dayTitle {
  font-size: 16px;
  margin-bottom: 18px;
}

.transactionInfoContainer {
  background-color: var(--mantine-color-white);
}

.collapseArrow {
  display: flex;
  align-items: center;
  margin-top: 0;
  cursor: pointer;
  svg {
    color: var(--mantine-color-gray-4);
  }
}

.collapseArrowOpened {
  height: 24px;
  transform: rotateX(180deg);
}

.historyItemCoinIcon {
  margin-right: 20px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: var(--mantine-color-violet-5);
  color: white;
  box-sizing: border-box;
  padding: 4px;
  svg {
    color: white;
  }
}

.historyItemDate {
  font-size: 12px;
  color: #9ca3af;
  font-weight: bold;
  white-space: nowrap;

  @include isMobile {
    font-size: 10px;
  }
}

.historyItemTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.historyItemAmountWithdraw {
  color: var(--mantine-color-gray-9);
}

.historyItemAmountSecondaryDescription {
  color: var(--mantine-color-gray-5);
  font-weight: bold;
  white-space: nowrap;
  text-align: right;
  font-size: 13px;
}

.itemAmount {
  font-family: "Actay Wide";
  text-align: right;
  white-space: nowrap;
  font-size: 14px;

  @media (max-width: 380px) {
    max-width: 7em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @include isMobile {
    font-size: 12px;
  }
}

.historyItemAmountDeposit {
  color: var(--mantine-color-green-5);
}

.historyItemAmountHasError {
  color: var(--mantine-color-red-4);
}

.historyDirectionIconWrapper {
  background: black;
  position: absolute;
  z-index: 1;
  width: 24px;
  height: 24px;
  margin: -25px 0 0 15px;
  border-radius: 12px;
  color: white;
  display: flex;
  svg {
    width: 80%;
    margin: auto;
    max-height: 15px;
  }
}

.historyDirectionIconHidden {
  visibility: hidden;
}

.historyDirectionIconReverse {
  transform: rotate(180deg);
}

.historyItemStatus {
  color: var(--mantine-color-gray-5);
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 12px;

  @include isMobile {
    display: none;
  }
}

.historyItemStatusMobile {
  display: none;
  @include isMobile {
    display: inline;
    font-size: 10px;
    margin-right: 0;
  }
}

.historyItemAmounts {
  display: flex;
  min-width: 200px;
  justify-content: flex-end;

  @include isMobile {
    min-width: max-content;
  }
}

.historyItemCollapseWrapper {
  padding: 24px 24px;
  background-color: #f9fafb;
}

.cardFieldWrapper {
  width: 100%;
  box-sizing: border-box;
  padding-right: 16px;
}

.cardFieldTitle {
  width: 100%;
  font-size: 12px;
  color: var(--mantine-color-gray-6);
}

.cardFieldContent {
  font-family: Actay;
  color: black;
  font-weight: normal;
  overflow-wrap: anywhere;
}

.loadMore {
  width: auto;
  margin-top: 32px;
}

.rowsContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px 16px;
  @include isMobile {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.divAlign {
  @include isMobile {
    display: none;
  }
}

.statusHint {
  vertical-align: middle;
}
