.itemContent {
  font-family: "Actay Wide";
  font-size: 14px;
  color: var(--mantine-color-gray-4);
  &.active {
    color: var(--mantine-color-green-4);
  }
}

.itemBullet {
  background-color: var(--mantine-color-gray-2);
}

.itemContentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.timelineItem:not(:first-of-type) {
  margin-top: 32px;
}