.root {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  gap: 8px;

  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(17, 24, 39, 0.7);
  height: 58px;
  width: 390px;
  border-radius: 100px;
  color: white;

  font-size: 16px;
}