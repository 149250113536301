@import "/src/shared/static/styles/mixins.scss";

.root {
  display: flex;
  gap: var(--mantine-spacing-md);
  @include isMobile {
    flex-direction: column;
  }
}

.content {
  flex-grow: 1;
}

.description {
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-gray-5);
}

.filesContainer {
  display: flex;
  flex-direction: column;
  gap: var(--mantine-spacing-sm);
}

.button {
  @include isMobile {
    width: 100%;
  }
}

.title {
  padding-top: 6px;
  @include isMobile {
    paddint-top: initial;
  }
}