.titleRed {
  color: var(--mantine-color-red-6);
}

.titleGreen {
  color: var(--mantine-color-green-6);
}

.verificationLevel {
  cursor: default;
  margin-right: 0;
  margin-left: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.cardContent {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}